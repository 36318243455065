












import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, User, Props, Reports } from '@/store';
import ManagerTondoReports from '@/pages/reports/ManagerTondoReports.vue';

@Component({
    components: {
        ManagerTondoReports
    }
})
export default class ManagerReports extends Vue {
    @Global.State('lang') lang;
    @Reports.Getter('commissionedFixtures') commissionedFixtures;
}
